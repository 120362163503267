import { ModalEmail } from "../ValePresente/modalEmail";
import { ModalWhatsapp } from "../ValePresente/modalWhatsapp";
import { Modal } from "../../components/Modal";

import { formatCurrentMoney, formatDateIso } from "../../util/helper";
import { Footer as FooterDesk } from "../../components/Footer";
import { ResponseVoucher } from "../../pages/MultiplosVoucherGrandCru";
import { Loader } from "../Loader";

import { Container } from "./stylesGrandCru";

import imgEditar from "../../assets/sistema/editar.svg";
import { useRef, useState } from "react";
import { QRCodeGenerator } from "../Qrcode";

interface PersonaliteQrCodeProps {
    setModalTutorial: (value: boolean) => void;
    voucher: ResponseVoucher;
    loading: boolean;
    name?: string;
}

export function PersonaliteQrCode(props: PersonaliteQrCodeProps) {
    const [modalEmail, setModalEmail] = useState(false);
    const [modalWhatsap, setModalWhatsapp] = useState(false);
    const componentRef = useRef<any>();

    const configWhats = {
        onClose: () => setModalWhatsapp(false),
    };

    const configEmail = {
        onClose: () => setModalEmail(false),
    };
    const data = props?.voucher?.voucher
        ? formatDateIso(props?.voucher?.voucher[0].date || "")
              .split("-")[0]
              .replace(/\./g, "/")
        : "";

    const validade = props?.voucher?.voucher
        ? props.voucher?.voucher[0].validade
        : "";

    const codigo = props?.voucher?.voucher
        ? props.voucher.voucher[0].codes[0]
        : ("" as string);

    return (
        <Container ref={componentRef}>
            {props.loading ? (
                <div className="loader">
                    <Loader />
                </div>
            ) : (
                <>
                    <div className="container">
                        <div className="container-text">
                            <h3>MINHAS VANTAGENS</h3>
                        </div>

                        <div className="container-par">
                            <h5>
                                CRÉDITO DE{" "}
                                {formatCurrentMoney(
                                    parseFloat(
                                        props?.voucher?.voucher
                                            ? props?.voucher?.voucher[0].valor +
                                                  ""
                                            : ""
                                    )
                                )}
                                <br />
                                {props.name}
                            </h5>

                            <p className="text-qr-code">
                                Escaneie o QR Code para resgatar:
                            </p>
                        </div>

                        <div className="card-retangulo grandCru">
                            <div className="container-voucher">
                                <QRCodeGenerator codigo={codigo} />

                                {validade === "VENCIDO" && (
                                    <small>Vencido em: {data}</small>
                                )}
                                {validade === "VENCIMENTO" && (
                                    <small>Vencimento em: {data}</small>
                                )}
                                {validade !== null &&
                                validade !== "VENCIDO" &&
                                validade !== "VENCIMENTO" ? (
                                    <small>
                                        Validade: {validade} dias a partir de{" "}
                                        {data}
                                    </small>
                                ) : null}
                                {validade === null && (
                                    <small className="campo-amarelo">
                                        Validade: {data}
                                    </small>
                                )}
                            </div>
                        </div>

                        <div className="btn-voucher">
                            <button
                                onClick={() => props.setModalTutorial(true)}
                            >
                                <img src={imgEditar} alt="" />
                                <p>Saiba como usar</p>
                            </button>
                        </div>
                    </div>
                </>
            )}

            <Modal
                component={ModalWhatsapp}
                active={modalWhatsap}
                rest={configWhats}
            />
            <Modal
                component={ModalEmail}
                active={modalEmail}
                rest={configEmail}
            />

            <FooterDesk />
        </Container>
    );
}
