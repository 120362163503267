import styled from "styled-components";

export const ModalStyles = styled.div`
    padding: 34px 24px 47px 24px;
    background-color: ${(props) => props.theme.colors.white};
    width: 375px;
    height: auto;

    .container-btns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        width: 100%;
        button {
            width: 100% !important;
            height: 50px;
        }
    }

    .container-sucess {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
            width: 166px;
            &.img-custom {
                width: 100px;
                margin-bottom: 16px;
            }
        }
        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: ${(props) => props.theme.fonts.mdl};
            line-height: 120%;
            text-align: center;
            color: ${(props) => props.theme.colors.primary};
            margin-bottom: 34px;
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: ${(props) => props.theme.fonts.md};
            line-height: 140%;
            text-align: center;
            color: ${(props) => props.theme.colors.gray800};
            margin-bottom: 105px;
        }
    }

    .container-header {
        padding-bottom: 22px;
        margin-bottom: 27px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e1e2e3;
        p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.primary};
        }
    }

    .container-content {
        width: 100%;
        .container-img {
            padding: 13px;
            border: 0.710526px solid #bcdfba;
            width: 54px;
            height: 54px;
            border-radius: 50%;
            margin: 0 auto;
            margin-bottom: 21px;
            img {
                width: 28px;
                display: block;
            }
        }
        span {
            display: block;
            width: 254px;
            margin: 0 auto;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 120%;
            text-align: center;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.primary};
            margin-bottom: 52px;
        }
    }
`;
