import styled from "styled-components";

export const Container = styled.footer`
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 12px;
        line-height: 125%;
        letter-spacing: 0%;
        text-align: center;
        vertical-align: middle;
    }
`;
