import styled from "styled-components";
import Dialog from "@mui/material/Dialog";

export const ModalStyles = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
        width: "100%",
        minWidth: "90%",
        height: "auto",
        margin: "0px",
        minHeight: "auto",
        boxShadow: "0px 0px 60px rgba(0, 0, 0, 0.05);",
        overflow: "unset",
        maxHeight: "95vh",
        "& > div": {
            overflowY: "auto",
            "&::-webkit-scrollbar": {
                "-webkit-appearance": "none",
            },
            "&::-webkit-scrollbar:vertical": {
                width: "5px",
            },
            "&::-webkit-scrollbar:horizontal": {
                height: "12px",
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(89, 92, 118, 0.5)",
            },
            "&::-webkit-scrollbar-track": {
                backgroundColor: "rgba(255, 255, 255, 0.1)",
            },
        },
    },

    ".container": {
        width: "90%",
    },

    "@media (min-width: 768px)": {
        "& .MuiPaper-root": {
            width: "auto",
            minWidth: "375px",
        },

        "&.modal-bottom": {
            ".MuiDialog-container": {
                alignItems: "center",
            },
        },
    },
    "@media (max-width: 480px)": {
        "& .MuiPaper-root": {
            width: "90%",
            minWidth: "auto",
        },
    },

    "&.popup": {
        ".MuiPaper-root": {
            maxWidth: "900px",
            maxHeight: "600px",
            width: "auto",
        },
    },
}));

export const ModalVideoStyles = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
        width: "100%",
        minWidth: "90%",
        height: "auto",
        margin: "0px",
        minHeight: "fit-content",
        maxWidth: "fit-content",
        maxHeight: "fit-content",
        boxShadow: "0px 0px 60px rgba(0, 0, 0, 0.05);",
        overflow: "hidden",
    },

    ".container": {
        width: "90%",
    },

    "@media (min-width: 768px)": {
        "& .MuiPaper-root": {
            width: "auto",
            minWidth: "auto",
        },

        "&.modal-bottom": {
            ".MuiDialog-container": {
                alignItems: "center",
            },
        },
    },
    "@media (max-width: 480px)": {
        "& .MuiPaper-root": {
            width: "90%",
            minWidth: "auto",
        },
    },
}));

export const Popup = styled.div`
    display: flex;
    overflow-y: auto !important;
    .close {
        position: absolute;
        right: 0;
        width: 30px;
        top: -30px;
        cursor: pointer;
    }
    @media (max-width: 480px) {
        .close {
            top: 0;
        }
    }
`;
