import { toast } from "react-toastify";

import { ModalEmail } from "../ValePresente/modalEmail";
import { ModalWhatsapp } from "../ValePresente/modalWhatsapp";
import { Modal } from "../../components/Modal";

import { formatDateIso } from "../../util/helper";
import { Footer as FooterDesk } from "../../components/Footer";
import { ResponseVoucher } from "../../pages/MultiplosVoucher";
import { Loader } from "../Loader";

import { Container } from "./styles";

// import imgRectangle from "../../assets/sistema/rectangle.png";
import imgFundo from "../../assets/sistema/fundo-voucher.png";
import imgCopy from "../../assets/sistema/copy.svg";
import imgEditar from "../../assets/sistema/editar.svg";
import { useRef, useState } from "react";

interface MultiplosVoucherProps {
    setModalTutorial: (value: boolean) => void;
    voucher: ResponseVoucher;
    loading: boolean;
}

export function MultiplosVoucher(props: MultiplosVoucherProps) {
    const [modalEmail, setModalEmail] = useState(false);
    const [modalWhatsap, setModalWhatsapp] = useState(false);
    const componentRef = useRef<any>();

    const width = window.screen.width;

    const configWhats = {
        onClose: () => setModalWhatsapp(false),
    };

    const configEmail = {
        onClose: () => setModalEmail(false),
    };

    function copy(value: string) {
        navigator.clipboard.writeText(value);
        toast.success(`Cupom copiado`);
    }

    const data = props?.voucher.voucher?.length
        ? formatDateIso(props.voucher.voucher[0]?.date || "")
              .split("-")[0]
              .replace(/\./g, "/")
        : "";
    const validade = props.voucher.voucher?.length
        ? props.voucher.voucher[0].validade
        : "";

    const nome = props.voucher.voucher ? props.voucher.voucher[0].nome : "";
    const nomeFormat = nome?.split(" ")[0];

    return (
        <Container ref={componentRef}>
            {props.loading ? (
                <div className="loader">
                    <Loader />
                </div>
            ) : (
                <>
                    <div className="container">
                        <div className="container-text">
                            <h3>MINHAS VANTAGENS </h3>
                        </div>

                        <div className="container-par">
                            <h5>
                                1 PAR DE INGRESSOS <br />
                                {nomeFormat}
                            </h5>
                        </div>

                        <div className="container-pares-de-ingressos">
                            {props.voucher.voucher?.map((item, key) => {
                                return (
                                    <div className="card-retangulo">
                                        <img src={imgFundo} alt="" />

                                        <div className="container-voucher">
                                            <span>código {key + 1}</span>
                                            <p
                                                className={`${
                                                    nomeFormat === "UCI"
                                                        ? "fontMenor"
                                                        : ""
                                                } roboto-mono`}
                                            >
                                                {item.codes[0]}
                                                <button
                                                    onClick={() =>
                                                        copy(
                                                            item.codes
                                                                ? item.codes[0]
                                                                : ""
                                                        )
                                                    }
                                                >
                                                    <img src={imgCopy} alt="" />
                                                    copiar <br />
                                                    código
                                                </button>
                                            </p>

                                            <div className="container-btn-validade">
                                                {validade === "VENCIDO" && (
                                                    <small>
                                                        Vencido em: {data}
                                                    </small>
                                                )}
                                                {validade !== null &&
                                                validade !== "VENCIDO" ? (
                                                    <small>
                                                        Validade: {validade}{" "}
                                                        dias a partir de {data}
                                                    </small>
                                                ) : null}
                                                {validade === null && (
                                                    <small className="campo-amarelo">
                                                        Validade: {data}
                                                    </small>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        <div className="btn-voucher">
                            <button
                                onClick={() => props.setModalTutorial(true)}
                            >
                                <img src={imgEditar} alt="" />
                                <p>Saiba como usar</p>
                            </button>
                        </div>
                    </div>
                </>
            )}

            <Modal
                component={ModalWhatsapp}
                active={modalWhatsap}
                rest={configWhats}
            />
            <Modal
                component={ModalEmail}
                active={modalEmail}
                rest={configEmail}
            />
            {width > 1280 ? <FooterDesk /> : <FooterDesk />}
        </Container>
    );
}
