import { Container } from "./styles";
import { Modal } from "../../../components/Modal";
import { ModalEmail } from "../../ValePresente/modalEmail";
import { ModalWhatsapp } from "../../ValePresente/modalWhatsapp";
import imgCalendario from "../../../assets/sistema/calendario.svg";
import img24hs from "../../../assets/sistema/24h.svg";
import imgPencil from "../../../assets/sistema/pencil.svg";
import { Footer } from "../Footer";
import { ResponseVoucher } from "../../../pages/MultiplosVoucherGrandCru";
import { useState } from "react";

interface PontosItauUniclassProps {
    setModalTutorial: (value: boolean) => void;
    voucher: ResponseVoucher;
    loading: boolean;
    name?: string;
}

export function PontosItauUniclass(props: PontosItauUniclassProps) {
    const [modalEmail, setModalEmail] = useState(false);
    const [modalWhatsap, setModalWhatsapp] = useState(false);

    const configWhats = {
        onClose: () => setModalWhatsapp(false),
        voucher: props.voucher,
    };

    const configEmail = {
        onClose: () => setModalEmail(false),
        voucher: props.voucher,
    };
    return (
        <>
            <Container>
                <div className="container">
                    <div className="container-titles">
                        <h3>Minhas Vantagens</h3>
                        <h3>1.600 pontos Itaú</h3>
                    </div>

                    <div className="container-resgate">
                        <div className="container-header">
                            <h4>Resgate efetuado,</h4>
                            <h4>agora é só aguardar!</h4>
                        </div>

                        <div className="container-cards">
                            <div className="container-card">
                                <div className="container-img">
                                    <img src={imgCalendario} alt="" />
                                </div>
                                <div className="container-text">
                                    <p>
                                        Os pontos Itaú serão creditados em até 7{" "}
                                        dias úteis.
                                    </p>
                                </div>
                            </div>
                            <div className="container-card">
                                <div className="container-img">
                                    <img src={img24hs} alt="" />
                                </div>
                                <div className="container-text">
                                    <p>
                                        Os pontos podem ser utilizados <br />
                                        em até 24 meses.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button
                        className="btn-uniclass"
                        onClick={() => props.setModalTutorial(true)}
                    >
                        <div className="container-img">
                            <img src={imgPencil} alt="" />
                        </div>
                        Saiba como usar
                    </button>
                </div>
            </Container>
            <Footer />

            <Modal
                component={ModalWhatsapp}
                active={modalWhatsap}
                rest={configWhats}
            />
            <Modal
                component={ModalEmail}
                active={modalEmail}
                rest={configEmail}
            />
        </>
    );
}
