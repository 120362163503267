import { useEffect, useState } from "react";
import { Container } from "./styles";
import QRCode from "qrcode-generator";

type CodigoProps = {
    codigo: string;
};

export function QRCodeGenerator(props: CodigoProps) {
    const [qrCode, setQrCode] = useState<string | null>(null);

    useEffect(() => {
        const qr = QRCode(0, "L"); // Nível de correção de erro: L
        qr.addData(props.codigo);
        qr.make();
        setQrCode(qr.createDataURL(8, 0)); // Tamanho 8, margem 0
    }, [props.codigo]);

    return (
        <Container>{qrCode && <img src={qrCode} alt="QR Code" />}</Container>
    );
}
