import { Form, FieldProps } from "formik";
import { FormVariacao } from "../type";
import { Select } from "../../Select";
import { useProduto } from "../../../Hook/useProduto";
import { Box } from "aplication-yetz";
import { useCallback } from "react";

// export interface FormCupomProps extends FieldProps<any, FormVariacao> {
//     hasVariacao: boolean;
// }

export function FormParceiro(props: FieldProps<any, FormVariacao>) {
    const { form } = props;
    const { setFieldValue, values } = form;
    const { parceiros } = useProduto();

    const parceiro_id = values.parceiro_id;

    const hasParceiro = useCallback(() => {
        if (parceiro_id) {
            return parceiros.filter((item) => item.value === parceiro_id)[0];
        }
        return null;
    }, [parceiro_id, parceiros]);

    return (
        <Form>
            <Box className="container-form" mt={2}>
                <div className="container-form">
                    <Select
                        placeholder="PARCEIROS"
                        onChange={(item) =>
                            setFieldValue("parceiro_id", item.value)
                        }
                        initalValue={hasParceiro()}
                        options={parceiros}
                    />
                </div>
            </Box>
        </Form>
    );
}
