import styled from "styled-components";

export const Container = styled.div`
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 24px;
    margin-bottom: 17px;
    img {
        display: block;
        width: 112px;
        height: 112px;
        margin: 0 auto !important;
    }
`;
