import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 93px 55px 93px 55px;
    text-align: center;
    width: 547px;
    position: relative;
    .container-close {
        position: absolute;
        top: 31px;
        right: 36px;
    }

    .container-img {
        margin-bottom: 14px;
    }
    h3 {
        font-family: "Inter";
        font-weight: 700;
        font-size: 24px;
        line-height: 100%;
        letter-spacing: 0%;
        color: #930035;
        margin-bottom: 16px;
    }

    p {
        font-family: "Inter";
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        letter-spacing: 0%;
        text-align: center;
        color: #4b4b4b;
        margin-bottom: 60px;
    }

    .container-btns {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        button {
            border: none;
            border-radius: 5px;
            font-family: "Inter";
            font-weight: 700;
            font-size: 16px;
            line-height: 100%;
            letter-spacing: 0%;
            padding: 16px 0px;
            cursor: pointer;
            width: 100%;
            color: #930035;
            border: 1px solid #930035;
            margin-bottom: 12px;
            &.solid {
                background-color: #930035;
                color: #fff;
            }
        }
    }
`;
