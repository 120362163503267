import { useState } from "react";
import imgclose from "../../../assets/sistema/close.svg";
import imgFecha from "../../../assets/sistema/fechar-modal.svg";

import { Container } from "./styles";

interface ModalsolicitarCancelamentoProps {
    handleClose: () => void;
    handleSolicitar: () => Promise<void>;
}

export function ModalsolicitarCancelamento(
    props: ModalsolicitarCancelamentoProps
) {
    const { handleClose, handleSolicitar } = props;
    const [loading, setLoading] = useState<boolean>(false);

    async function solictar() {
        try {
            setLoading(true);
            await handleSolicitar();
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Container>
            <button className="container-close" onClick={handleClose}>
                <img src={imgFecha} alt="Fechar" />
            </button>

            <div className="container-img">
                <img src={imgclose} alt="Fechar" />
            </div>

            <h3>Solicitar Cancelamento</h3>
            <p>
                Deseja realmente solicitar o cancelamento? <br />
                Esta ação é irreversível.{" "}
            </p>

            <div className="container-btns">
                <button className="solid" disabled={loading} onClick={solictar}>
                    Solicitar
                </button>
                <button onClick={handleClose}>Cancelar</button>
            </div>
        </Container>
    );
}
