import { Box, Flex, Text } from "aplication-yetz";
import { Form, FieldProps } from "formik";
import { Toggle } from "../../Toggle";
import { FormVariacao } from "../type";
import { useEffect, useState } from "react";
import { Option } from "../../../types";
import { Variacao } from "../../../Hook/useProduto";
import { SelectAsync } from "../../Select";
import { api } from "../../../services";

export interface FormSmashProps extends FieldProps<any, FormVariacao> {
    hasVariacao: boolean;
    variacao: Variacao;
    clearSelect: React.RefObject<HTMLButtonElement>;
}

export function FormSmash(props: FormSmashProps) {
    const { form, hasVariacao, variacao, clearSelect } = props;
    const { values, setFieldValue } = form;
    const { fornecedor_id_smash, fornecedor_id_todo, fornecedor_id_giffit } =
        values;

    const [optionsTodo, setOptionsTodo] = useState<Option[]>([]);
    const [optionsSmash, setOptionsSmash] = useState<Option[]>([]);
    const [optionsGiffty, setOptionsGiffty] = useState<Option[]>([]);

    const [selectedOptionSmash, setSelectedOptionSmash] = useState<Option[]>(
        []
    );
    const [selectedOptionTodo, setSelectedOptionTodo] = useState<Option[]>([]);
    const [selectedOptionGiffty, setSelectedOptionGiffty] = useState<Option[]>(
        []
    );

    const [disabledSmash, setDisabledSmash] = useState(false);
    const [disabledTodo, setDisabledTodo] = useState(false);
    const [disabledGiffty, setDisabledGiffty] = useState(false);

    const arrayMatchSmash: Option[] = [];
    const arrayMatchTodo: Option[] = [];
    const arrayMatchGiffty: Option[] = [];

    async function produtosExternal(id: number) {
        try {
            const response = await api.get(`fornecedor/${id}/produtos-api`);

            const { data } = response;
            if (id === 1) {
                setOptionsSmash(
                    data.map((item: any) => ({
                        value: item.key,
                        label: item.description,
                    }))
                );
            } else if (id === 2) {
                setOptionsGiffty(
                    data.map((item: any) => ({
                        value: item.key,
                        label: item.description,
                    }))
                );
            } else if (id === 3) {
                setOptionsTodo(
                    data.map((item: any) => ({
                        value: item.key,
                        label: item.description,
                    }))
                );
            }

            data.forEach((item: any) => {
                variacao.metas?.forEach((m) => {
                    if (
                        m.key === item.key &&
                        parseFloat(variacao.valor_reais) >=
                            parseFloat(item.min_value) &&
                        parseFloat(variacao.valor_reais) <=
                            parseFloat(item.max_value)
                    ) {
                        if (m.fornecedor_id === 1) {
                            arrayMatchSmash.push({
                                value: item.key,
                                label: item.description,
                            });
                        } else if (m.fornecedor_id === 2) {
                            arrayMatchGiffty.push({
                                value: item.key,
                                label: item.description,
                            });
                        } else if (m.fornecedor_id === 3) {
                            arrayMatchTodo.push({
                                value: item.key,
                                label: item.description,
                            });
                        }
                    }
                });
            });
            if (id === 1) {
                setSelectedOptionSmash(arrayMatchSmash);
            } else if (id === 2) {
                setSelectedOptionGiffty(arrayMatchGiffty);
            } else if (id === 3) {
                setSelectedOptionTodo(arrayMatchTodo);
            }
        } catch (error: any) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (hasVariacao) {
            const { metas } = variacao;

            setDisabledSmash(
                metas?.find((m) => m.fornecedor_id === 1)?.fornecedor_id === 1
                    ? false
                    : true
            );
            setDisabledGiffty(
                metas?.find((m) => m.fornecedor_id === 2)?.fornecedor_id === 2
                    ? false
                    : true
            );
            setDisabledTodo(
                metas?.find((m) => m.fornecedor_id === 3)?.fornecedor_id === 3
                    ? false
                    : true
            );
        }
    }, [hasVariacao, variacao]);

    return (
        <Form>
            <Text fontWeight="600" fontSize="mdl" color="black" mb="30px">
                Compras via API
            </Text>

            <Flex justifyContent="space-between" width="100%">
                <Text fontWeight="600" mb="20px">
                    SMASHPOINTS
                </Text>
                <Toggle
                    active={fornecedor_id_smash === 1 ? true : false}
                    onClick={() => {
                        setFieldValue(
                            "fornecedor_id_smash",
                            fornecedor_id_smash === 1 ? 0 : 1
                        );
                        setDisabledSmash(!disabledSmash);
                    }}
                />
            </Flex>

            <Box mb="32px">
                <SelectAsync
                    clear={clearSelect}
                    disabled={disabledSmash}
                    defaultValue={selectedOptionSmash && selectedOptionSmash[0]}
                    onChange={(value) =>
                        setFieldValue("key_smash", value.value)
                    }
                    placeholder="PRODUTO VINCULADO"
                    options={optionsSmash}
                    setOptions={setOptionsSmash}
                    getOptions={async () => await produtosExternal(1)}
                />
            </Box>

            <Flex justifyContent="space-between" width="100%">
                <Text fontWeight="600" mb="20px">
                    GIFFTY
                </Text>
                <Toggle
                    active={fornecedor_id_giffit === 2 ? true : false}
                    onClick={() => {
                        setFieldValue(
                            "fornecedor_id_giffit",
                            fornecedor_id_giffit === 2 ? 0 : 2
                        );
                        setDisabledGiffty(!disabledGiffty);
                    }}
                />
            </Flex>

            <Box mb="32px">
                <SelectAsync
                    clear={clearSelect}
                    disabled={disabledGiffty}
                    defaultValue={
                        selectedOptionGiffty && selectedOptionGiffty[0]
                    }
                    onChange={(value) =>
                        setFieldValue("key_giffty", value.value)
                    }
                    placeholder="PRODUTO VINCULADO"
                    options={optionsGiffty}
                    setOptions={setOptionsGiffty}
                    getOptions={() => produtosExternal(2)}
                />
            </Box>

            <Flex justifyContent="space-between" width="100%">
                <Text fontWeight="600" mb="20px">
                    TODO CARTÕES
                </Text>
                <Toggle
                    active={fornecedor_id_todo === 3 ? true : false}
                    onClick={() => {
                        setFieldValue(
                            "fornecedor_id_todo",
                            fornecedor_id_todo === 3 ? 0 : 3
                        );
                        setDisabledTodo(!disabledTodo);
                    }}
                />
            </Flex>

            <Box mb="32px">
                <SelectAsync
                    clear={clearSelect}
                    disabled={disabledTodo}
                    defaultValue={selectedOptionTodo && selectedOptionTodo[0]}
                    onChange={(value) => setFieldValue("key_todo", value.value)}
                    placeholder="PRODUTO VINCULADO"
                    options={optionsTodo}
                    setOptions={setOptionsTodo}
                    getOptions={() => produtosExternal(3)}
                />
            </Box>
        </Form>
    );
}
