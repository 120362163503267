export const loadingAnimation = [
    {
        v: "5.1.10",
        fr: 24,
        ip: 16,
        op: 52,
        w: 600,
        h: 600,
        nm: "Loader 5",
        ddd: 0,
        assets: [],
        layers: [
            {
                ddd: 0,
                ind: 1,
                ty: 4,
                nm: "Shape Layer 4",
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: {
                        a: 1,
                        k: [
                            {
                                i: { x: 0.7, y: 1 },
                                o: { x: 0.3, y: 0 },
                                n: "0p7_1_0p3_0",
                                t: 28,
                                s: [388.699, 300, 0],
                                e: [388.699, 275, 0],
                                to: [0, 0, 0],
                                ti: [0, 0, 0],
                            },
                            {
                                i: { x: 0.7, y: 1 },
                                o: { x: 0.3, y: 0 },
                                n: "0p7_1_0p3_0",
                                t: 36,
                                s: [388.699, 275, 0],
                                e: [388.699, 325, 0],
                                to: [0, 0, 0],
                                ti: [0, 0, 0],
                            },
                            {
                                i: { x: 0.7, y: 1 },
                                o: { x: 0.3, y: 0 },
                                n: "0p7_1_0p3_0",
                                t: 44,
                                s: [388.699, 325, 0],
                                e: [388.699, 300, 0],
                                to: [0, 0, 0],
                                ti: [0, 0, 0],
                            },
                            { t: 52 },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nvar amp, freq, decay, n, t, v;\ntry {\n    amp = div(effect('Position - Overshoot')('ADBE Slider Control-0001'), 2.5), freq = div(effect('Position - Bounce')('ADBE Slider Control-0001'), 20), decay = div(effect('Position - Friction')('ADBE Slider Control-0001'), 20), n = 0, 0 < numKeys && (n = nearestKey(time).index, key(n).time > time && n--), t = 0 === n ? 0 : time - key(n).time, $bm_rt = 0 < n ? (v = velocityAtTime(sub(key(n).time, div(thisComp.frameDuration, 10))), sum(value, div(mul(mul(div(v, 100), amp), Math.sin(mul(mul(mul(freq, t), 2), Math.PI))), Math.exp(mul(decay, t))))) : value;\n} catch (e$$4) {\n    $bm_rt = value = value;\n}",
                    },
                    a: { a: 0, k: [-247.301, -57.301, 0], ix: 1 },
                    s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                ef: [
                    {
                        ty: 5,
                        nm: "Position - Overshoot",
                        np: 3,
                        mn: "ADBE Slider Control",
                        ix: 1,
                        en: 1,
                        ef: [
                            {
                                ty: 0,
                                nm: "Slider",
                                mn: "ADBE Slider Control-0001",
                                ix: 1,
                                v: {
                                    a: 0,
                                    k: 20,
                                    ix: 1,
                                    x: "var $bm_rt;\n$bm_rt = clamp(value, 0, 100);",
                                },
                            },
                        ],
                    },
                    {
                        ty: 5,
                        nm: "Position - Bounce",
                        np: 3,
                        mn: "ADBE Slider Control",
                        ix: 2,
                        en: 1,
                        ef: [
                            {
                                ty: 0,
                                nm: "Slider",
                                mn: "ADBE Slider Control-0001",
                                ix: 1,
                                v: {
                                    a: 0,
                                    k: 40,
                                    ix: 1,
                                    x: "var $bm_rt;\n$bm_rt = clamp(value, 0, 100);",
                                },
                            },
                        ],
                    },
                    {
                        ty: 5,
                        nm: "Position - Friction",
                        np: 3,
                        mn: "ADBE Slider Control",
                        ix: 3,
                        en: 1,
                        ef: [
                            {
                                ty: 0,
                                nm: "Slider",
                                mn: "ADBE Slider Control-0001",
                                ix: 1,
                                v: {
                                    a: 0,
                                    k: 40,
                                    ix: 1,
                                    x: "var $bm_rt;\n$bm_rt = clamp(value, 0, 100);",
                                },
                            },
                        ],
                    },
                ],
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                d: 1,
                                ty: "el",
                                s: { a: 0, k: [25.398, 25.398], ix: 2 },
                                p: { a: 0, k: [0, 0], ix: 3 },
                                nm: "Ellipse Path 1",
                                mn: "ADBE Vector Shape - Ellipse",
                                hd: false,
                            },
                            {
                                ty: "st",
                                c: {
                                    a: 0,
                                    k: [
                                        0.532643995098, 0.532643995098,
                                        0.532643995098, 1,
                                    ],
                                    ix: 3,
                                },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 0, ix: 5 },
                                lc: 1,
                                lj: 1,
                                ml: 4,
                                nm: "Stroke 1",
                                mn: "ADBE Vector Graphic - Stroke",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: {
                                    a: 0,
                                    k: [
                                        0.298039215686, 0.298039215686,
                                        0.298039215686, 1,
                                    ],
                                    ix: 4,
                                },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                nm: "Fill 1",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [-247.301, -57.301], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Ellipse 1",
                        np: 3,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                ],
                ip: 4,
                op: 124,
                st: 4,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 2,
                ty: 4,
                nm: "Shape Layer 3",
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: {
                        a: 1,
                        k: [
                            {
                                i: { x: 0.7, y: 1 },
                                o: { x: 0.3, y: 0 },
                                n: "0p7_1_0p3_0",
                                t: 24,
                                s: [328.699, 300, 0],
                                e: [328.699, 275, 0],
                                to: [0, 0, 0],
                                ti: [0, 0, 0],
                            },
                            {
                                i: { x: 0.7, y: 1 },
                                o: { x: 0.3, y: 0 },
                                n: "0p7_1_0p3_0",
                                t: 32,
                                s: [328.699, 275, 0],
                                e: [328.699, 325, 0],
                                to: [0, 0, 0],
                                ti: [0, 0, 0],
                            },
                            {
                                i: { x: 0.7, y: 1 },
                                o: { x: 0.3, y: 0 },
                                n: "0p7_1_0p3_0",
                                t: 40,
                                s: [328.699, 325, 0],
                                e: [328.699, 300, 0],
                                to: [0, 0, 0],
                                ti: [0, 0, 0],
                            },
                            { t: 48 },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nvar amp, freq, decay, n, t, v;\ntry {\n    amp = div(effect('Position - Overshoot')('ADBE Slider Control-0001'), 2.5), freq = div(effect('Position - Bounce')('ADBE Slider Control-0001'), 20), decay = div(effect('Position - Friction')('ADBE Slider Control-0001'), 20), n = 0, 0 < numKeys && (n = nearestKey(time).index, key(n).time > time && n--), t = 0 === n ? 0 : time - key(n).time, $bm_rt = 0 < n ? (v = velocityAtTime(sub(key(n).time, div(thisComp.frameDuration, 10))), sum(value, div(mul(mul(div(v, 100), amp), Math.sin(mul(mul(mul(freq, t), 2), Math.PI))), Math.exp(mul(decay, t))))) : value;\n} catch (e$$4) {\n    $bm_rt = value = value;\n}",
                    },
                    a: { a: 0, k: [-247.301, -57.301, 0], ix: 1 },
                    s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                ef: [
                    {
                        ty: 5,
                        nm: "Position - Overshoot",
                        np: 3,
                        mn: "ADBE Slider Control",
                        ix: 1,
                        en: 1,
                        ef: [
                            {
                                ty: 0,
                                nm: "Slider",
                                mn: "ADBE Slider Control-0001",
                                ix: 1,
                                v: {
                                    a: 0,
                                    k: 20,
                                    ix: 1,
                                    x: "var $bm_rt;\n$bm_rt = clamp(value, 0, 100);",
                                },
                            },
                        ],
                    },
                    {
                        ty: 5,
                        nm: "Position - Bounce",
                        np: 3,
                        mn: "ADBE Slider Control",
                        ix: 2,
                        en: 1,
                        ef: [
                            {
                                ty: 0,
                                nm: "Slider",
                                mn: "ADBE Slider Control-0001",
                                ix: 1,
                                v: {
                                    a: 0,
                                    k: 40,
                                    ix: 1,
                                    x: "var $bm_rt;\n$bm_rt = clamp(value, 0, 100);",
                                },
                            },
                        ],
                    },
                    {
                        ty: 5,
                        nm: "Position - Friction",
                        np: 3,
                        mn: "ADBE Slider Control",
                        ix: 3,
                        en: 1,
                        ef: [
                            {
                                ty: 0,
                                nm: "Slider",
                                mn: "ADBE Slider Control-0001",
                                ix: 1,
                                v: {
                                    a: 0,
                                    k: 40,
                                    ix: 1,
                                    x: "var $bm_rt;\n$bm_rt = clamp(value, 0, 100);",
                                },
                            },
                        ],
                    },
                ],
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                d: 1,
                                ty: "el",
                                s: { a: 0, k: [25.398, 25.398], ix: 2 },
                                p: { a: 0, k: [0, 0], ix: 3 },
                                nm: "Ellipse Path 1",
                                mn: "ADBE Vector Shape - Ellipse",
                                hd: false,
                            },
                            {
                                ty: "st",
                                c: {
                                    a: 0,
                                    k: [
                                        0.532643995098, 0.532643995098,
                                        0.532643995098, 1,
                                    ],
                                    ix: 3,
                                },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 0, ix: 5 },
                                lc: 1,
                                lj: 1,
                                ml: 4,
                                nm: "Stroke 1",
                                mn: "ADBE Vector Graphic - Stroke",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: {
                                    a: 0,
                                    k: [
                                        0.298039215686, 0.298039215686,
                                        0.298039215686, 1,
                                    ],
                                    ix: 4,
                                },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                nm: "Fill 1",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [-247.301, -57.301], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Ellipse 1",
                        np: 3,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                ],
                ip: 0,
                op: 120,
                st: 0,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 3,
                ty: 4,
                nm: "Shape Layer 2",
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: {
                        a: 1,
                        k: [
                            {
                                i: { x: 0.7, y: 1 },
                                o: { x: 0.3, y: 0 },
                                n: "0p7_1_0p3_0",
                                t: 20,
                                s: [268.699, 300, 0],
                                e: [268.699, 275, 0],
                                to: [0, 0, 0],
                                ti: [0, 0, 0],
                            },
                            {
                                i: { x: 0.7, y: 1 },
                                o: { x: 0.3, y: 0 },
                                n: "0p7_1_0p3_0",
                                t: 28,
                                s: [268.699, 275, 0],
                                e: [268.699, 325, 0],
                                to: [0, 0, 0],
                                ti: [0, 0, 0],
                            },
                            {
                                i: { x: 0.7, y: 1 },
                                o: { x: 0.3, y: 0 },
                                n: "0p7_1_0p3_0",
                                t: 36,
                                s: [268.699, 325, 0],
                                e: [268.699, 300, 0],
                                to: [0, 0, 0],
                                ti: [0, 0, 0],
                            },
                            { t: 44 },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nvar amp, freq, decay, n, t, v;\ntry {\n    amp = div(effect('Position - Overshoot')('ADBE Slider Control-0001'), 2.5), freq = div(effect('Position - Bounce')('ADBE Slider Control-0001'), 20), decay = div(effect('Position - Friction')('ADBE Slider Control-0001'), 20), n = 0, 0 < numKeys && (n = nearestKey(time).index, key(n).time > time && n--), t = 0 === n ? 0 : time - key(n).time, $bm_rt = 0 < n ? (v = velocityAtTime(sub(key(n).time, div(thisComp.frameDuration, 10))), sum(value, div(mul(mul(div(v, 100), amp), Math.sin(mul(mul(mul(freq, t), 2), Math.PI))), Math.exp(mul(decay, t))))) : value;\n} catch (e$$4) {\n    $bm_rt = value = value;\n}",
                    },
                    a: { a: 0, k: [-247.301, -57.301, 0], ix: 1 },
                    s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                ef: [
                    {
                        ty: 5,
                        nm: "Position - Overshoot",
                        np: 3,
                        mn: "ADBE Slider Control",
                        ix: 1,
                        en: 1,
                        ef: [
                            {
                                ty: 0,
                                nm: "Slider",
                                mn: "ADBE Slider Control-0001",
                                ix: 1,
                                v: {
                                    a: 0,
                                    k: 20,
                                    ix: 1,
                                    x: "var $bm_rt;\n$bm_rt = clamp(value, 0, 100);",
                                },
                            },
                        ],
                    },
                    {
                        ty: 5,
                        nm: "Position - Bounce",
                        np: 3,
                        mn: "ADBE Slider Control",
                        ix: 2,
                        en: 1,
                        ef: [
                            {
                                ty: 0,
                                nm: "Slider",
                                mn: "ADBE Slider Control-0001",
                                ix: 1,
                                v: {
                                    a: 0,
                                    k: 40,
                                    ix: 1,
                                    x: "var $bm_rt;\n$bm_rt = clamp(value, 0, 100);",
                                },
                            },
                        ],
                    },
                    {
                        ty: 5,
                        nm: "Position - Friction",
                        np: 3,
                        mn: "ADBE Slider Control",
                        ix: 3,
                        en: 1,
                        ef: [
                            {
                                ty: 0,
                                nm: "Slider",
                                mn: "ADBE Slider Control-0001",
                                ix: 1,
                                v: {
                                    a: 0,
                                    k: 40,
                                    ix: 1,
                                    x: "var $bm_rt;\n$bm_rt = clamp(value, 0, 100);",
                                },
                            },
                        ],
                    },
                ],
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                d: 1,
                                ty: "el",
                                s: { a: 0, k: [25.398, 25.398], ix: 2 },
                                p: { a: 0, k: [0, 0], ix: 3 },
                                nm: "Ellipse Path 1",
                                mn: "ADBE Vector Shape - Ellipse",
                                hd: false,
                            },
                            {
                                ty: "st",
                                c: {
                                    a: 0,
                                    k: [
                                        0.532643995098, 0.532643995098,
                                        0.532643995098, 1,
                                    ],
                                    ix: 3,
                                },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 0, ix: 5 },
                                lc: 1,
                                lj: 1,
                                ml: 4,
                                nm: "Stroke 1",
                                mn: "ADBE Vector Graphic - Stroke",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: {
                                    a: 0,
                                    k: [
                                        0.298039215686, 0.298039215686,
                                        0.298039215686, 1,
                                    ],
                                    ix: 4,
                                },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                nm: "Fill 1",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [-247.301, -57.301], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Ellipse 1",
                        np: 3,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                ],
                ip: -4,
                op: 116,
                st: -4,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 4,
                ty: 4,
                nm: "Shape Layer 1",
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: {
                        a: 1,
                        k: [
                            {
                                i: { x: 0.7, y: 1 },
                                o: { x: 0.3, y: 0 },
                                n: "0p7_1_0p3_0",
                                t: 16,
                                s: [208.699, 300, 0],
                                e: [208.699, 275, 0],
                                to: [0, 0, 0],
                                ti: [0, 0, 0],
                            },
                            {
                                i: { x: 0.7, y: 1 },
                                o: { x: 0.3, y: 0 },
                                n: "0p7_1_0p3_0",
                                t: 24,
                                s: [208.699, 275, 0],
                                e: [208.699, 325, 0],
                                to: [0, 0, 0],
                                ti: [0, 0, 0],
                            },
                            {
                                i: { x: 0.7, y: 1 },
                                o: { x: 0.3, y: 0 },
                                n: "0p7_1_0p3_0",
                                t: 32,
                                s: [208.699, 325, 0],
                                e: [208.699, 300, 0],
                                to: [0, 0, 0],
                                ti: [0, 0, 0],
                            },
                            { t: 40 },
                        ],
                        ix: 2,
                        x: "var $bm_rt;\nvar amp, freq, decay, n, t, v;\ntry {\n    amp = div(effect('Position - Overshoot')('ADBE Slider Control-0001'), 2.5), freq = div(effect('Position - Bounce')('ADBE Slider Control-0001'), 20), decay = div(effect('Position - Friction')('ADBE Slider Control-0001'), 20), n = 0, 0 < numKeys && (n = nearestKey(time).index, key(n).time > time && n--), t = 0 === n ? 0 : time - key(n).time, $bm_rt = 0 < n ? (v = velocityAtTime(sub(key(n).time, div(thisComp.frameDuration, 10))), sum(value, div(mul(mul(div(v, 100), amp), Math.sin(mul(mul(mul(freq, t), 2), Math.PI))), Math.exp(mul(decay, t))))) : value;\n} catch (e$$4) {\n    $bm_rt = value = value;\n}",
                    },
                    a: { a: 0, k: [-247.301, -57.301, 0], ix: 1 },
                    s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                ef: [
                    {
                        ty: 5,
                        nm: "Position - Overshoot",
                        np: 3,
                        mn: "ADBE Slider Control",
                        ix: 1,
                        en: 1,
                        ef: [
                            {
                                ty: 0,
                                nm: "Slider",
                                mn: "ADBE Slider Control-0001",
                                ix: 1,
                                v: {
                                    a: 0,
                                    k: 20,
                                    ix: 1,
                                    x: "var $bm_rt;\n$bm_rt = clamp(value, 0, 100);",
                                },
                            },
                        ],
                    },
                    {
                        ty: 5,
                        nm: "Position - Bounce",
                        np: 3,
                        mn: "ADBE Slider Control",
                        ix: 2,
                        en: 1,
                        ef: [
                            {
                                ty: 0,
                                nm: "Slider",
                                mn: "ADBE Slider Control-0001",
                                ix: 1,
                                v: {
                                    a: 0,
                                    k: 40,
                                    ix: 1,
                                    x: "var $bm_rt;\n$bm_rt = clamp(value, 0, 100);",
                                },
                            },
                        ],
                    },
                    {
                        ty: 5,
                        nm: "Position - Friction",
                        np: 3,
                        mn: "ADBE Slider Control",
                        ix: 3,
                        en: 1,
                        ef: [
                            {
                                ty: 0,
                                nm: "Slider",
                                mn: "ADBE Slider Control-0001",
                                ix: 1,
                                v: {
                                    a: 0,
                                    k: 40,
                                    ix: 1,
                                    x: "var $bm_rt;\n$bm_rt = clamp(value, 0, 100);",
                                },
                            },
                        ],
                    },
                ],
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                d: 1,
                                ty: "el",
                                s: { a: 0, k: [25.398, 25.398], ix: 2 },
                                p: { a: 0, k: [0, 0], ix: 3 },
                                nm: "Ellipse Path 1",
                                mn: "ADBE Vector Shape - Ellipse",
                                hd: false,
                            },
                            {
                                ty: "st",
                                c: {
                                    a: 0,
                                    k: [
                                        0.532643995098, 0.532643995098,
                                        0.532643995098, 1,
                                    ],
                                    ix: 3,
                                },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 0, ix: 5 },
                                lc: 1,
                                lj: 1,
                                ml: 4,
                                nm: "Stroke 1",
                                mn: "ADBE Vector Graphic - Stroke",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: {
                                    a: 0,
                                    k: [
                                        0.298039215686, 0.298039215686,
                                        0.298039215686, 1,
                                    ],
                                    ix: 4,
                                },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                nm: "Fill 1",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [-247.301, -57.301], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Ellipse 1",
                        np: 3,
                        cix: 2,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                ],
                ip: -8,
                op: 112,
                st: -8,
                bm: 0,
            },
        ],
        markers: [],
    },
];
