import { Container } from "./styles";
export function Footer() {
    const year = new Date().getFullYear();
    return (
        <Container>
            <p>
                YETZ LTDA | CNPJ: 28.325.166/0001-05 | {year} <br />
                Todos os direitos reservados
            </p>
        </Container>
    );
}
