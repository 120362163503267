import { Column } from "react-table";
import { Data, FormVariacao, FormTutorial } from "./type";
import * as Yup from "yup";

export const column: Column<Data>[] = [
    {
        Header: "Cod. variação",
        accessor: "cod_variacao",
    },
    {
        Header: "valor em reais",
        accessor: "valor_em_reais",
    },
    {
        Header: "valor em YETZ",
        accessor: "valor_em_yetz",
    },
    {
        Header: "Alerta de estoque",
        accessor: "alerta_de_estoque",
    },
    {
        Header: "Aviso estoque",
        accessor: "status_alerta_estoque",
    },
    {
        Header: "Disponibilidade",
        accessor: "disponibilidade",
    },
    {
        Header: "Ações",
        accessor: "acoes",
    },
];

export const initialValues: FormVariacao = {
    nome: "",
    status: 1,
    valor_yetz: "",
    valor_reais: "",
    alerta_estoque: "",
    tipo: "voucher",
    tipo_desconto: "",
    outlet_limpeza_agendada: false,
    outlet_dia_limpeza: 0,
    outlet_hora_limpeza: 0,
};

export const Schema = Yup.object().shape({
    nome: Yup.string().required("Campo é obrigatório"),
    status: Yup.number().required("Campo é obrigatório"),
    valor_yetz: Yup.string(),
    valor_reais: Yup.string(),
    alerta_estoque: Yup.string().required("Campo é obrigatório"),
    tipo: Yup.string(),
    tipo_desconto: Yup.string(),
    desconto: Yup.string(),
    parceiro_id: Yup.string().nullable(),
    outlet_limpeza_agendada: Yup.boolean(),

    outlet_dia_limpeza: Yup.number()
        .nullable() // Permite que o campo seja nulo
        .transform((value, originalValue) => {
            return originalValue ? originalValue : value;
        }) // Se o valor original for verdadeiro, retorna o valor original, caso contrário, retorna o valor atual
        .when("outlet_limpeza_agendada", {
            is: true, // Verifica se o campo outlet_limpeza_agendada é true
            then: (schema) => schema.required("Campo é obrigatório"), // Torna obrigatório
            otherwise: (schema) => schema.nullable(), // Permite valores nulos caso não seja obrigatório
        }),

    outlet_hora_limpeza: Yup.number()
        .nullable()
        .transform((value, originalValue) => {
            return originalValue ? originalValue : value;
        })
        .when("outlet_limpeza_agendada", {
            is: true,
            then: (schema) => schema.required("Campo é obrigatório"),
            otherwise: (schema) => schema.nullable(),
        }),
});

export const initialValuesTutorial: FormTutorial = {
    tutorial_observacao: "",
    tutorial_url: "",
    tutorial_passos: "[]",
    external_link: "",
    external_link_label: "",
};

export const SchemaTutorial = Yup.object().shape({
    tutorial_observacao: Yup.string().nullable(true),
    tutorial_url: Yup.string().nullable(true),
    tutorial_passos: Yup.string().required("Campo é obrigatório"),
    external_link: Yup.string(),
    external_link_label: Yup.string(),
});
