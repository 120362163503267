import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    *{
        margin: 0px;
        padding: 0px;
        outline: 0;
        box-sizing: border-box;
    }

    html{
        font-size: 93.75%;
    }

    ul{
        list-style: none;
    }

    button{
        transition: opacity 0.5s;
        &[disabled]{
            cursor: not-allowed;
            opacity: 0.6;
            transition: opacity 0.5s;
        }
    }

    #root{
        overflow-x: hidden;
    }

    .container{
        width: 1280px;
        margin: 0 auto;
    }

    .sandBox{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        h1{
            font-size: 12rem;
            text-transform: uppercase;
            margin: 0px;
            padding: 0px;
            mix-blend-mode: darken;
            color: #93003533;
            transform: rotate(-45deg);
        }
    }


    .Toastify__toast-theme--dark{
        background-color: #FF6200 !important;
    }

    .Toastify__close-button > svg{
        fill: #fff;
    }

    .Toastify__close-button--light{
        opacity: 1;
    }

    .loader{
        height: calc(100vh - 81px);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media(max-width: 1366px){
        .container{
            width: 90%;
        }
    }

    .content{
        background-color: white;
        position: absolute;
        top: 0px;
        left: 148px;
        width: calc(100% - 148px);
        height: 100vh;
        overflow: scroll;
        padding-top: 3rem;
        padding-left: 5rem;
        padding-right: 42px;
        display: flex;
        flex-direction: column;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar:vertical {
            width: 5px;
        }

        &::-webkit-scrollbar:horizontal {
            height: 12px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(89, 92, 118, 0.5);
        }

        &::-webkit-scrollbar-track {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }

    @media(max-width: 1024px){
        .sandBox{
            h1{
                font-size: 11rem;
            }
        }
    }
    @media(max-width: 768px){
        .sandBox{
            h1{
                font-size: 6rem;
            }
        }
    }

    @media(max-width: 480px){
        .sandBox{
            h1{
                font-size: 4rem;
                transform: unset;
                color: #93003533;
            }
        }
    }

    html{
        @media(max-width: 1080px){
            font-size: 93.75%;
        }

        @media(max-width: 720px){
            font-size: 87.5%;
        }
    }

    body{
        -webkit-font-smoothing: antialiased;
    }

    body, input, textarea, button{
        font-family: 'Inter', sans-serif;
        font-weight: 400;
    }

    input[type="file"]{
        display: none;
    }
    
    h1, h2, h3, h4, h5, h6, strong{
        font-weight: 600;
    }

    a{
        text-decoration: none;
    }

    button{
        cursor: pointer;
        background-color: transparent;
        border: none;
    }

    [disabled]{
        opacity: 0.6;
        cursor: not-allowed;
    }

    .disabled{
        opacity: 0.6;
        cursor: not-allowed;
        pointer-events: none;
    }

    .rdw-editor-main{
        border: 1px solid #F1F1F1;
        margin-bottom: 32px;
        padding: 20px;
    }

    .table {
        .header{
            .th{
                font-weight: bold; 
                text-transform: uppercase;
            }
        }
        .tr {
            font-weight: 500;
            &.body {
                border-bottom: 1px solid #ececec;
            }
        }
    }

    .container-tabs {
        position: relative;
        padding-top: 34px;
        width: 328px;
        margin: 0 auto;

        .tab {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            opacity: 0;
            pointer-events: none;
            transition: all 0.3s ease-in-out;

            &.active {
                opacity: 1;
                pointer-events: auto;
                position: static;
                transition: all 0.6s ease-in-out;
            }
        }
    }

    .btn-bloqueado{
        width: 22px;
        height: 22px;
        display: block !important;
        padding: 0px !important;
        span{
            pointer-events: none;
        }
        &:before{
            left: 50%;
        }
    }

    .container-loading {
        width: 100%;
        height: calc(100vh - 230px);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .container-form{
        margin-bottom: 21px;
        .MuiFormControl-root{
            .MuiInputLabel-root{
                left: 0px;
            }
        }
        &.status{
            border-bottom: 1px solid ${({ theme }) =>
                theme.colors.gray300} !important;
                height: 48px;
                display: flex;
                align-items: center;
                width: 100%;
        }

        &.bloquear{
            pointer-events: none;
            opacity: 0.8;
        }
        &:last-child{
            margin-bottom: 0px;
        }
    }

    .input-text{
        .MuiInputLabel-root{
            color: ${({ theme }) => theme.colors.gray300} !important;
        }

        .MuiInput-root{
            &::after{
                border-bottom: 1px solid ${({ theme }) =>
                    theme.colors.primary} !important;
            }
            &:before{
                border-bottom: 1px solid ${({ theme }) =>
                    theme.colors.gray300} !important;
            }
            .MuiInput-input{
                color: ${({ theme }) => theme.colors.primary};
                font-weight: bold;
            }

            .MuiInput-colorSecondary{
                color: ${({ theme }) => theme.colors.primary} !important;
            }
        }
    }

    .combo-box-filtro{
        span{
            color: ${({ theme }) => theme.colors.white};
        }
        &.MuiAutocomplete-root{
            .MuiAutocomplete-input{
                color: ${({ theme }) => theme.colors.white};
            }

            .MuiAutocomplete-popper{
                background: linear-gradient(90deg, #19FFFF 1.92%, rgba(25, 255, 255, 0) 105.77%);
            }

            .MuiInputLabel-root{
                &.MuiInputLabel-formControl{
                    color: ${({ theme }) => theme.colors.action};
                    font-weight: bold;
                    font-size: 12px;
                }
            }
            .MuiOutlinedInput-notchedOutline{
                border: none;
            }
        }
    }

    .combo-box-select{
    
        span{
            color: ${({ theme }) => theme.colors.white};
        }
        &.multipleSelect{
            .MuiInput-root{
                .MuiInput-input{
                    margin-top: 16px;
                }
            }
        }
        &.MuiAutocomplete-root{
            .MuiAutocomplete-tag{
                background: ${({ theme }) => theme.colors.primary};
            }
            .MuiOutlinedInput-root{
                padding: 0px !important;
                .MuiAutocomplete-input{
                    padding: 4px 4px 4px 0px;
                    &:before{
                        border-bottom: 1px solid ${({ theme }) =>
                            theme.colors.gray300} !important;
                    }
                }
            }
            .MuiInput-root{
                &::after{
                    border-bottom: 1px solid ${({ theme }) =>
                        theme.colors.primary} !important;
                }
                &:before{
                    border-bottom: 1px solid ${({ theme }) =>
                        theme.colors.gray300} !important;
                }
            }
            &:hover{
                .MuiOutlinedInput-notchedOutline{
                    border: 1px solid #13393B;
                }
            }
            .MuiAutocomplete-input{
                color: ${({ theme }) => theme.colors.primary};
                font-weight: bold;
                &::placeholder{
                    color: ${({ theme }) => theme.colors.gray800};
                    font-weight: 400;
                }
            }

            .MuiInputLabel-root{
                left: 0px;
                top: 0px;
                &.MuiInputLabel-formControl{
                    color: ${({ theme }) => theme.colors.gray300};
                    font-weight: 400;
                    font-size: 1rem;
                }
            }
            .MuiAutocomplete-endAdornment{
                top: calc(50% - 12px);
                right: 20px !important;
            }
            .MuiOutlinedInput-notchedOutline{
                border: 1px solid transparent !important;
            }
        }
    }

    .container-calendario{
        .MuiFormControl-root{
        width: 100%;
        .MuiOutlinedInput-input{
            color: ${({ theme }) => theme.colors.primary};
            font-weight: 600;
        }

        .MuiInputBase-formControl{
            /* background-color: red; */
            &:hover{
                .MuiOutlinedInput-notchedOutline{
                    border-color: ${({ theme }) => theme.colors.gray300};
                }
            }
        }

        .MuiInputLabel-root{
            left: -13px;
            
        }
        //.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root
        .MuiOutlinedInput-notchedOutline{
            border: none;
            border-radius: unset;
            border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};
        }

        .MuiOutlinedInput-input{
            padding: 16.5px 0px;
            /* padding-bottom: 5px; */
            /* padding-top: 24px; */
        }
    }
    }

`;
