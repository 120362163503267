import styled from "styled-components";
import bgBtn from "../../../assets/sistema/back-ground-btn.svg";

export const Container = styled.div`
    background-color: #0131ff;
    height: calc(100vh - 48px);
    width: 100%;
    .container {
        width: 308px;
        padding-top: 69px;

        .container-titles {
            margin-bottom: 61px;
            h3 {
                font-family: "Inter";
                font-weight: 700;
                font-size: 24px;
                line-height: 100%;
                letter-spacing: 0%;
                text-align: center;
                color: #ffffff;
                &:first-child {
                    margin-bottom: 15px;
                }
            }
        }

        .container-resgate {
            background-color: #f1f1f1;
            border-radius: 8px;
            padding: 13px 16px 23px 16px;
            margin-bottom: 40px;
            .container-header {
                padding-bottom: 15px;
                margin-bottom: 17px;
                border-bottom: 1px solid #d9d9d9;
                h4 {
                    font-family: "Inter";
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 22.4px;
                    letter-spacing: 0%;
                    text-align: center;
                    color: #000000;
                }
            }
            .container-cards {
                .container-card {
                    display: flex;
                    align-items: center;
                    margin-bottom: 16px;
                    background-color: #ffffff;
                    border-radius: 8px;
                    padding: 16px 17px 15px 24px;
                    .container-text {
                        font-family: "Inter";
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 100%;
                        letter-spacing: 0%;
                        color: #000000;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .container-img {
                        margin-right: 16px;
                    }
                }
            }
        }

        button {
            &.btn-uniclass {
                background: url(${bgBtn});
                background-size: 100% 100%;
                border: none;
                width: 100%;
                border-radius: 8px;
                padding: 13px 0;
                font-family: "Inter";
                font-weight: 500;
                font-size: 13.56px;
                line-height: 100%;
                letter-spacing: 0%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #000d3c;
                cursor: pointer;

                .container-img {
                    margin-right: 8px;
                }
            }
        }
    }
`;
