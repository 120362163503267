import { toast } from "react-toastify";

import { ModalEmail } from "../ValePresente/modalEmail";
import { ModalWhatsapp } from "../ValePresente/modalWhatsapp";
import { Modal } from "../../components/Modal";

import { formatDateIso } from "../../util/helper";
import { Footer as FooterDesk } from "../../components/Footer";
import { ResponseVoucher } from "../../pages/MultiplosVoucherGrandCru";
import { Loader } from "../Loader";
import { Footer } from "./footer";

import { Container } from "./stylesGrandCru";

import imgCopy from "../../assets/sistema/copy.svg";
import imgEditar from "../../assets/sistema/editar.svg";
import { useRef, useState } from "react";

interface SchweppesintenseProps {
    setModalTutorial: (value: boolean) => void;
    voucher: ResponseVoucher;
    loading: boolean;
    name?: string;
}

export function Schweppesintense(props: SchweppesintenseProps) {
    const [modalEmail, setModalEmail] = useState(false);
    const [modalWhatsap, setModalWhatsapp] = useState(false);
    const componentRef = useRef<any>();
    const width = window.screen.width;
    const hasCupom =
        props?.voucher?.voucher && props?.voucher?.voucher[0].cupom;
    const password = props?.voucher?.voucher
        ? props.voucher.voucher[0].password
        : "";
    const configWhats = {
        onClose: () => setModalWhatsapp(false),
        voucher: props.voucher,
    };

    const configEmail = {
        onClose: () => setModalEmail(false),
        voucher: props.voucher,
    };
    const data = props?.voucher?.voucher
        ? formatDateIso(props?.voucher?.voucher[0].date || "")
              .split("-")[0]
              .replace(/\./g, "/")
        : "";

    const validade = props?.voucher?.voucher
        ? props.voucher?.voucher[0].validade
        : "";

    function copy(text: string) {
        const el = document.createElement("textarea");
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        toast.success(`Copiado`);
        return "Texto copiado com sucesso!";
    }

    return (
        <Container ref={componentRef} className="schweppesintense">
            {props.loading ? (
                <div className="loader">
                    <Loader />
                </div>
            ) : (
                <>
                    <div className="container">
                        <div className="container-text">
                            {width <= 480 ? (
                                <>
                                    <h3>{props.name}</h3>
                                    <h3>é viver a melhor experiência!</h3>
                                </>
                            ) : (
                                <>
                                    <h3>
                                        {props.name}

                                        <span>
                                            {" "}
                                            <br />é viver a <br></br> melhor
                                            experiência!
                                        </span>
                                    </h3>
                                </>
                            )}
                        </div>

                        <div className="container-par">
                            <h5>{props.name}</h5>
                        </div>

                        <div className="card-retangulo grandCru">
                            <div className="container-voucher">
                                <div className="item">
                                    {hasCupom ? (
                                        <span>código</span>
                                    ) : (
                                        <span>código</span>
                                    )}

                                    <p className="roboto-mono small">
                                        {props.voucher.voucher
                                            ? props.voucher.voucher[0].codes[0].replace(
                                                  /\W/g,
                                                  ""
                                              )
                                            : ""}
                                        <button
                                            onClick={() =>
                                                copy(
                                                    props.voucher.voucher
                                                        ? props.voucher
                                                              .voucher[0]
                                                              .codes[0]
                                                        : ""
                                                )
                                            }
                                        >
                                            <img src={imgCopy} alt="" />
                                            copiar <br />
                                            código
                                        </button>
                                    </p>
                                </div>
                                {props?.voucher?.voucher &&
                                props?.voucher?.voucher[0].password ? (
                                    <div className="item">
                                        <span>senha</span>
                                        <p>{password}</p>
                                        <button
                                            onClick={() => copy(password || "")}
                                        >
                                            <img src={imgCopy} alt="" />
                                        </button>
                                    </div>
                                ) : null}

                                {validade === "VENCIDO" && (
                                    <small>Vencido em: {data}</small>
                                )}
                                {validade === "VENCIMENTO" && (
                                    <small>Vencimento em: {data}</small>
                                )}
                                {validade !== null &&
                                validade !== "VENCIDO" &&
                                validade !== "VENCIMENTO" ? (
                                    <small>
                                        Validade: {validade} dias a partir de{" "}
                                        {data}
                                    </small>
                                ) : null}
                                {validade === null && (
                                    <small className="campo-amarelo">
                                        Validade: {data}
                                    </small>
                                )}
                            </div>
                        </div>

                        <div className="btn-voucher">
                            <button
                                onClick={() => props.setModalTutorial(true)}
                            >
                                <img src={imgEditar} alt="" />
                                <p>Saiba como usar</p>
                            </button>
                        </div>

                        <h4 className="text">Como funciona o {props.name} </h4>

                        <div className="container-descricao">
                            <p className="text">
                                {props?.voucher?.voucher
                                    ? props?.voucher?.voucher[0].descricao
                                    : ""}
                            </p>
                        </div>
                    </div>
                </>
            )}

            <Modal
                component={ModalWhatsapp}
                active={modalWhatsap}
                rest={configWhats}
            />
            <Modal
                component={ModalEmail}
                active={modalEmail}
                rest={configEmail}
            />

            {width > 1280 ? (
                <FooterDesk />
            ) : (
                <Footer bgColor="linear-gradient(196deg, #E86972 -5.68%, #FEBF3E 89.16%), var(--Itau-Uniclass-AZUL-UNICLASS, #0131FF);" />
            )}
        </Container>
    );
}
