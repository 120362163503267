import React, { createContext, useContext, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { api } from "../services";

import { User } from "./useUser";
import { UpdateVariacao, Variacao } from "./useProduto";
import { Fornecedor } from "./useCompras";

export interface DetalheEstoqueData {
    detalheEstoque: DetalheEstoque;
    updateVariacao: (
        value: UpdateVariacao,
        id: number
    ) => Promise<AxiosResponse>;
    updateData: (value: UpdateDate, id: number) => Promise<AxiosResponse>;
    extrair: (value: Extrair) => Promise<AxiosResponse>;
    solicitarCancelamento(id: number): Promise<void>;
}

interface DetalheEstoqueProvider {
    children: React.ReactNode;
}

export interface HistoryProps {
    id?: number;
}

export interface DetalheEstoque {
    data: data[];
    variacao: Variacao;
}

export interface data {
    id: number;
    atualizar_validade: boolean;
    user_id: number;
    nome: string;
    status: string;
    created_at: string;
    variacao_total: number;
    variacao_disp: number;
    validade: string | null;
    total: number;
    total_reais: string;
    total_reais_format: string;
    user: User;
    vencido: boolean;
    outlet: boolean;
    pre_outlet: boolean;
    valido: boolean;
    quantidade_reembolsada: string;
    permitir_cancelamento: boolean;
    fornecedor: Fornecedor;
}

export interface UpdateDate {
    variacao_id: number;
    carga_id: number;
    data: string;
}

export interface Extrair {
    variacao_id: number;
    carga_id: number;
}

const DetalheEstoqueContext = createContext<DetalheEstoqueData>(
    {} as DetalheEstoqueData
);

export function UseDetalheEstoqueProvider(props: DetalheEstoqueProvider) {
    const { children } = props;
    const history = useHistory<HistoryProps>();

    const [detalheEstoque, setDetalheEstoque] = useState<DetalheEstoque>(
        {} as DetalheEstoque
    );

    async function updateVariacao(value: UpdateVariacao, id: number) {
        try {
            const response = await api.post<Variacao>(
                `/produto/variacao/${id}`,
                {
                    ...value,
                }
            );

            const { data } = response;

            setDetalheEstoque({
                ...detalheEstoque,
                variacao: {
                    ...detalheEstoque.variacao,
                    status_alerta_estoque: data.status_alerta_estoque,
                },
            });

            toast.success("Variacao atualizada com sucesso");
            return response;
        } catch (error: any) {
            console.log(error);

            return error;
        }
    }

    async function updateData(values: UpdateDate, id: number) {
        try {
            const response = await api.post<Variacao>(`carga/atualizar-data`, {
                ...values,
            });

            await getDetalheEstoque(id);
            console.log(response);
            toast.success("Variacao atualizada com sucesso");
            return response;
        } catch (error: any) {
            console.log(error);

            return error;
        }
    }

    async function extrair(dados: Extrair) {
        try {
            const response = await api.post(`carga/extrair`, { ...dados });

            if (response.status === 200) {
                toast.success("Carga em Extração");
            }

            await getDetalheEstoque(dados.variacao_id);

            return response;
        } catch (error: any) {
            if (error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Ocorreu um erro ao realizar a extração!");
            }
            return error;
        }
    }

    async function getDetalheEstoque(id: number) {
        try {
            const { data } = await api.get<DetalheEstoque>(
                `produto/variacao/${id}/cargas`
            );
            setDetalheEstoque(data);
        } catch (error) {
            console.log(error);
            toast.error("Erro ao carregar detalhe do estoque");
        }
    }

    async function solicitarCancelamento(id: number) {
        try {
            const { data } = await api.post(
                `estoque/devolucao-fornecedor/carga/${id}`
            );
            toast.success(data.message);
            await getDetalheEstoque(id);
        } catch (error) {
            console.log(error);
            toast.error("Erro ao solicitar cancelamento");
        }
    }

    useEffect(() => {
        if (history.location.state?.id) {
            getDetalheEstoque(history.location.state.id);
        }
    }, [history.location.state?.id]);

    return (
        <DetalheEstoqueContext.Provider
            value={{
                detalheEstoque,
                updateVariacao,
                updateData,
                extrair,
                solicitarCancelamento,
            }}
        >
            {children}
        </DetalheEstoqueContext.Provider>
    );
}

export function useDetalheEstoque() {
    const context = useContext(DetalheEstoqueContext);

    return context;
}
