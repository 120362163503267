import { Column } from "react-table";
import { Data } from "./types";
import { BoxDetalheProdutoProps } from "../../components/BoxDetalheProduto/types";
import img01 from "../../assets/sistema/logo-card-01.svg";
import img02 from "../../assets/sistema/logo-card-02.svg";
import img03 from "../../assets/sistema/logo-card-03.svg";
import img04 from "../../assets/sistema/logo-card-04.svg";
import img05 from "../../assets/sistema/logo-card-05.svg";
import img06 from "../../assets/sistema/logo-card-06.svg";

export const arrayCard: BoxDetalheProdutoProps[] = [
    {
        img: img01,
        subTitulo: "003224655",
        titulo: "código",
    },
    {
        img: img02,
        subTitulo: "Alimentação",
        titulo: "Categoria",
    },
    {
        img: img03,
        subTitulo: "Voucher Digital",
        titulo: "Tipo",
    },
    {
        img: img04,
        subTitulo: "entra em Outlet",
        titulo: "30 dias",
    },
    {
        img: img05,
        subTitulo: "Desconto Outlet",
        titulo: "50%",
    },
    {
        img: img06,
        subTitulo: "utilizado emz",
        titulo: "Loja Virtual",
    },
];

export const column: Column<Data>[] = [
    // {
    //     Header: "",
    //     accessor: "icone",
    //     width: 50,
    //     maxWidth: 50,
    // },
    // {
    //     Header: "Tipo",
    //     accessor: "tipo",
    //     width: 100,
    //     maxWidth: 100,
    // },

    {
        Header: "ID",
        accessor: "id",
        width: 50,
        maxWidth: 50,
    },
    {
        Header: "Data/Hora",
        accessor: "dataHora",
    },
    {
        Header: "Quantidade",
        accessor: "quantidade",
        width: 100,
        maxWidth: 100,
    },
    {
        Header: "QUANTIDADE DE CANCELAMENTOS",
        accessor: "qtd_cancelamentos",
        width: 100,
        maxWidth: 100,
    },
    {
        Header: "Enviado Por",
        accessor: "enviadoPor",
    },
    {
        Header: "Fornecedor",
        accessor: "fornecedor",
        width: 100,
        maxWidth: 100,
    },
    {
        Header: "Solicitar cancelamento",
        accessor: "solicitar_cancelamento",
    },
    {
        Header: "Validade",
        accessor: "validade",
    },
];
