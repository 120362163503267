import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { Flex, Heading, ButtonSystem } from "aplication-yetz";
import {
    Formik,
    Field,
    Form,
    ErrorMessage,
    FormikProps,
    FormikHelpers,
} from "formik";

import { Produto, useProduto, Variacao } from "../../Hook/useProduto";

import {
    formatCurrentMoney,
    formatCurrentYETZ,
    realParaFloat,
} from "../../util/helper";
import { Input } from "../Input";

import { Select } from "../Select";
import { Option } from "../../components/Select/types";

import { FormVariacao } from "./type";
import { Schema, initialValues } from "./config";

import fecharModalImg from "../../assets/sistema/fechar-modal.svg";
import { ModalVariacao } from "./styles";
import { FormVoucher } from "./formVoucher";
import { FormCupom } from "./formCupom";
import { FormAgendaLimpeza } from "./formAgendaLimpeza";
import { FormSmash } from "./FormSmash";
import { When } from "../When";
import { FormParceiro } from "./FormParceiro";

interface NovaVariacaoProps {
    rest: {
        onClose: () => void;
        id: number;
        variacao: Variacao;
        produto: Produto;
    };
}

export function NovaVariacao(props: NovaVariacaoProps) {
    const { rest } = props;
    const { variacao, produto } = rest;

    const { storeVariacao, updateVariacao } = useProduto();
    const [loading, setLoading] = useState<boolean>(false);
    const [value, setValue] = useState<FormVariacao>(initialValues);
    const hasVariacao = Object.values(variacao).length ? true : false;
    const clearSelect = useRef<HTMLButtonElement>(null);
    const tipoProduto = produto.tipo;

    const tipoVariacao: Option[] = useMemo(
        () => [
            {
                label: "CUPOM",
                value: 1,
            },
            {
                label: "VOUCHER",
                value: 2,
            },
        ],
        []
    );

    async function handleSubmit(
        values: FormVariacao,
        actions: FormikHelpers<FormVariacao>
    ) {
        setLoading(true);

        let {
            valor_reais,
            valor_yetz,
            fornecedor_id_smash,
            fornecedor_id_todo,
            fornecedor_id_giffit,
        } = values;

        const metas: any = [];

        let smash: any = {};
        if (fornecedor_id_smash === 1) {
            smash = {
                fornecedor_id: 1,
                key: values.key_smash,
            };
            metas.push(smash);
        }

        let todo: any = {};
        if (fornecedor_id_todo === 3) {
            todo = {
                fornecedor_id: 3,
                key: values.key_todo,
            };
            metas.push(todo);
        }

        let giffit: any = {};
        if (fornecedor_id_giffit === 2) {
            giffit = {
                fornecedor_id: 2,
                key: values.key_giffty,
            };
            metas.push(giffit);
        }

        valor_yetz = valor_yetz.replace(/\./g, "");

        const values_format: FormVariacao = {
            // ...restValues,
            nome: values.nome,
            status: values.status,
            alerta_estoque: values.alerta_estoque,
            valor_reais: realParaFloat(valor_reais) || (null as any),
            valor_yetz: parseFloat(valor_yetz) || (null as any),
            metas,
            tipo: values.tipo || "voucher",
            porcentagem: values.porcentagem || null,
            outlet_limpeza_agendada: values.outlet_limpeza_agendada,
            outlet_dia_limpeza: values.outlet_dia_limpeza || null,
            outlet_hora_limpeza: values.outlet_hora_limpeza,
            parceiro_id: values.parceiro_id || null,
        };

        if (!values_format?.parceiro_id) delete values_format.parceiro_id;

        if (!values_format?.outlet_dia_limpeza)
            delete values_format.outlet_dia_limpeza;
        if (!Number.isInteger(values_format?.outlet_hora_limpeza))
            delete values_format.outlet_hora_limpeza;

        const response = hasVariacao
            ? await updateVariacao(values_format, variacao.id)
            : await storeVariacao({
                  ...values_format,
                  produto_id: rest.id,
              });

        if (response.status === 201 || response.status === 200) {
            actions.resetForm();
            rest.onClose();
            if (clearSelect.current) {
                clearSelect.current.click();
            }
        }

        setLoading(false);
    }

    useEffect(() => {
        if (hasVariacao) {
            const {
                nome,
                status,
                valor_yetz,
                valor_reais,
                alerta_estoque,
                metas,
                tipo,
            } = variacao;

            setValue({
                nome,
                status,
                valor_yetz: formatCurrentYETZ(valor_yetz),
                valor_reais: formatCurrentMoney(parseFloat(valor_reais + "")),
                alerta_estoque: alerta_estoque + "",
                fornecedor_id_smash: metas?.find((m) => m.fornecedor_id === 1)
                    ?.fornecedor_id,
                fornecedor_id_giffit: metas?.find((m) => m.fornecedor_id === 2)
                    ?.fornecedor_id,
                key_smash: metas?.find((m) => m.fornecedor_id === 1)?.key,
                fornecedor_id_todo: metas?.find((m) => m.fornecedor_id === 3)
                    ?.fornecedor_id,
                key_todo: metas?.find((m) => m.fornecedor_id === 3)?.key,
                key_giffty: metas?.find((m) => m.fornecedor_id === 2)?.key,
                parceiro_id: variacao?.parceiro_id,
                //pegar banco
                tipo: tipo || "voucher",
                tipo_desconto: "",

                // fazer
                outlet_limpeza_agendada: variacao?.outlet_limpeza_agendada,
                outlet_dia_limpeza: variacao?.outlet_dia_limpeza,
                outlet_hora_limpeza: variacao?.outlet_hora_limpeza,
            });
        }
    }, [hasVariacao, variacao]);

    const hasTipo = useCallback(() => {
        if (hasVariacao) {
            return tipoVariacao.filter(
                (item) =>
                    item.label.toLowerCase() === variacao?.tipo.toLowerCase()
            )[0];
        }
        return null;
    }, [hasVariacao, tipoVariacao, variacao?.tipo]);

    return (
        <ModalVariacao>
            <Flex alignItems="center" justifyContent="space-between" mb="34px">
                <Heading as="h2" fontWeight="600">
                    {hasVariacao ? "Editar " : "Nova "} variação
                </Heading>

                <button
                    onClick={() => {
                        rest.onClose();
                        setValue(initialValues);
                    }}
                    type="button"
                >
                    <img src={fecharModalImg} alt="" />
                </button>
            </Flex>

            <Formik
                initialValues={value}
                enableReinitialize
                validationSchema={Schema}
                onSubmit={async (values: FormVariacao, actions) => {
                    await handleSubmit(values, actions);
                }}
            >
                {(props: FormikProps<FormVariacao>) => {
                    const { setFieldValue, values } = props;
                    const { tipo } = values;

                    console.log(props);

                    return (
                        <Form>
                            <div className="container-form">
                                <Field
                                    component={Input}
                                    name="nome"
                                    label="NOME"
                                />
                                <ErrorMessage component="small" name="nome" />
                            </div>

                            <div className="container-form">
                                <Select
                                    initalValue={hasTipo()}
                                    placeholder="TIPO DE VARIAÇÃO"
                                    onChange={(item) => {
                                        setFieldValue(
                                            "tipo",
                                            item.label.toLowerCase()
                                        );
                                        setFieldValue("valor_reais", "");
                                        setFieldValue("valor_yetz", "");
                                        setFieldValue("desconto", "");
                                        setFieldValue("porcentagem", "");
                                    }}
                                    options={tipoVariacao}
                                />
                            </div>

                            <Field
                                component={FormAgendaLimpeza}
                                variacao={variacao}
                            />

                            {tipo === "cupom" ? (
                                <Field component={FormCupom} />
                            ) : (
                                <Field component={FormVoucher} />
                            )}

                            <When expr={hasVariacao}>
                                <>
                                    <When expr={tipoProduto === "VOUCHER_YETZ"}>
                                        <Field component={FormParceiro} />
                                    </When>
                                    <When expr={tipoProduto !== "VOUCHER_YETZ"}>
                                        <Field
                                            component={FormSmash}
                                            clearSelect={clearSelect}
                                            hasVariacao={hasVariacao}
                                            variacao={variacao}
                                        />
                                    </When>
                                </>
                            </When>

                            <ButtonSystem
                                mt="16px"
                                p="16px 32px"
                                fontSize="xs"
                                loading={loading}
                                type="submit"
                                width={132}
                                height={53}
                                heightSpiner={30}
                                widthSpnier={30}
                            >
                                {hasVariacao ? "Editar" : "Adicionar"}
                            </ButtonSystem>
                        </Form>
                    );
                }}
            </Formik>
        </ModalVariacao>
    );
}
